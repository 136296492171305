import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  // Redirect
} from 'react-router-dom'

import { LOGO_DELIVEREE } from 'constants/imageConstants'

// I18N (HOOK)
import { withTranslation } from 'react-i18next'

// Assets
import './App.scss'

// Utils
import { verifyUrlCountryCode, detectCountryByUrl, configHeadHelmet, getParamFromURL, handleRedirectNotificationCenterScreen, isWebViewAndroid } from '../utils/common'

// API
import DriverOnboarding from '../api/DriverOnboarding'

// Actions
import { updateTopLeftMenu } from '../actions/topLeftMenu'
import { updateCompetitorApp } from '../actions/competitorApp'

// Components
// Veriry access?
import VerifyAccess from './VerifyAccess'
import Loading, { loading } from '../components/Common/Loading'
import RedirectHelper from '../components/ReactRouter/RedirectHelper'
import isEmpty from 'lodash/isEmpty'
 
//setup env for face-api
import * as canvas from 'canvas'
import * as faceapi from 'face-api.js'
import { ACCESS_TOKEN } from 'constants/appConstants'
const { ImageData } = canvas
faceapi.env.monkeyPatch({
  Canvas: HTMLCanvasElement,
  Image: HTMLImageElement,
  ImageData: ImageData,
  Video: HTMLVideoElement,
  createCanvasElement: () => document.createElement('canvas'),
  createImageElement: () => document.createElement('img')
})

// Containers
const Page404Container = React.lazy(() => import('../containers/Page404Container'))


const mapStateToProps = (state) => {
  return {
    topLeftMenu: state.topLeftMenu
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateTopLeftMenuAction: (data) => {
      dispatch(updateTopLeftMenu(data))
    },
    updateCompetitorApp: (data) => {
      dispatch(updateCompetitorApp(data))
    }
  }
}

/**
 * @class App
 */
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
    }
    this.driverOnboardingAPI = new DriverOnboarding()
  }

  componentDidMount() {

    const { updateCompetitorApp, updateTopLeftMenuAction } = this.props
    const token = localStorage.getItem(ACCESS_TOKEN)
    const isOpenNC = getParamFromURL('open_nc')
    if (isWebViewAndroid() && isOpenNC === 'true' && token) handleRedirectNotificationCenterScreen(-1)
    //update competitor apps to store
    const getSuspendApp = (data) => {
      const parseData = isEmpty(data) ? [] : data.split(',')
      updateCompetitorApp(parseData)
    }

    const getDeviceModel = (data) => {
      const parseData = isEmpty(data) ? '' : data.slice(1,-1)
      window.localStorage.setItem('deviceModel', parseData)
    }

    window.getSuspendApp = getSuspendApp
    window.getDeviceModel = getDeviceModel

    // https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae
    // to fix the vh issue on mobile
    // re-calculate the vh value base on window.innerHeight instead
    const cssRootElement = document.querySelector(':root')
    if (cssRootElement) {
      cssRootElement.style.setProperty('--vh', window.innerHeight / 100 + 'px')
    }

    verifyUrlCountryCode(window.location, (result, ignoreUrl) => {
      this.setState({
        isLoading: true,
      }, () => {
        if (ignoreUrl) {
          window._$g.rdr(ignoreUrl, true)
        } else if (!result) {
          window._$g.rdr('404')
        }
      })
    })
    const countryObject = detectCountryByUrl()
    this.driverOnboardingAPI.getMenus({
      country_code: countryObject.countryCode
    }).then(response => {
      updateTopLeftMenuAction(response)
    })

    //load model for face-api
    this.loadModelFaceApi()
  }

  loadModelFaceApi = async () => {
    await faceapi.nets.tinyFaceDetector.loadFromUri('/assets/models')
    const img = document.createElement('img')
    img.width = 300
    img.height = 300
    img.src = LOGO_DELIVEREE
    await faceapi.detectAllFaces(img, new faceapi.TinyFaceDetectorOptions({ inputSize: 224 }))
  }

  render() {
    const { isLoading } = this.state
    const { t } = this.props

    if (!isLoading) return null

    const verifyCountry = JSON.parse(window.localStorage.getItem('dlvr_verifyCountry'))
    const country = verifyCountry.isTransportify === true ? '' : `/${verifyCountry.country_code}`
    return (
      <Router>
        <React.Suspense fallback={loading()}>
          {configHeadHelmet(t('common.site_title'), t('common.site_description'), verifyCountry.language_code)}
          {/* Helper for fast redirect */}
          <RedirectHelper ref={ref => (ref && (window._$g.rdr = (ref && ref.go)))} />
          {/* Dynamic Popup */}
          <div id="DimensionPopup" className="DynamicPopupWrapper" />
          {/* Lodaing Popup */}
          <Loading ref={ref => (ref && (window._$g.dialogs = (ref && ref.show)))} />
          <Switch>
            <Route
              exact
              path={`${country}/404`}
              name={"Page 404"}
              component={Page404Container}
            />
            <Route
              path="/"
              name={"Select Area"}
              component={VerifyAccess}
            />
          </Switch>
        </React.Suspense>
      </Router>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App))
