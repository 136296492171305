/**
 * @flow
 */
export const MAX_CODE_LOGIN = 6
export const MAX_CODE_VERIFY = 4
export const MAX_CODE_APPROVAL = 6
export const WIDTH_CAMERA = 320
export const CALL_LOADING = 'CALL_LOADING'
export const UPDATE_COMPETITOR_APP = 'UPDATE_COMPETITOR_APP'
export const UPDATE_TOPLEFT_MENU = 'UPDATE_TOPLEFT_MENU'
export const UPDATE_AREA = 'UPDATE_AREA'
export const CREATE_DRIVER_ONBOARDING = 'CREATE_DRIVER_ONBOARDING'
export const UPDATE_DRIVER_ONBOARDING = 'UPDATE_DRIVER_ONBOARDING'
export const UPDATE_SHOW_GOOGLE_MAP = 'UPDATE_SHOW_GOOGLE_MAP'


export const TRANSPORTIFY_URL = process.env.REACT_APP_TRANSPORTIFY_URL
export const DELIVEREE_URL = process.env.REACT_APP_DELIVEREE_URL
export const MAP_STYLE_URL = process.env.REACT_APP_MAP_STYLE_URL

export const API_GATEWAY_URL = process.env.REACT_APP_API_GATEWAY_URL
export const API_GW_URL = process.env.REACT_APP_API_GW_URL

export const API_AUTH_URL = process.env.REACT_APP_DELIVEREE_AUTHENTICATION_SERVICE_URL

/* GOOGLE API */
export const GMAPS_BROWSER_KEY = process.env.REACT_APP_GMAPS_BROWSER_KEY
export const GMAPS_API_VERSION = process.env.REACT_APP_GMAPS_API_VERSION

/* reCAPTCHA */
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
export const RECAPTCHA_SECRET_KEY = process.env.REACT_APP_RECAPTCHA_SECRET_KEY

/* LANGUAGE */
export const LOCALE = process.env.REACT_APP_LOCALE
export const ACCEPT_LANGUAGE = {
  id: 'id',
  th: 'th',
  vn: 'vi',
  ph: 'en',
}
export const COUNTRY_NAMES = {
  'PH': 'Philippines',
  'TH': 'Thailand',
  'ID': 'Indonesia',
  'VN': 'Vietnam'
}
export const COUNTRY_PHONE_CODE = {
  id: '+62',
  ph: '+63',
  th: '+66',
  vn: '+84'
}

/* VALIDATE PHONE FORMAT */
export const PHONE_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

/* For Sentry */
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || ''

// Vehicle select other option
export const OTHER_OPTION = 0

/* EXAM STATUS */
export const EXAM_STATUS = {
  'CLEARED': 'cleared',
  'FAILED': 'failed',
  'BLOCKED': 'blocked',
  'UNCHECKED': 'unchecked',
  'LOCKED': 'locked'
}

export const FIREBASE_ENV = process.env.REACT_APP_RAILS_ENV

export const SUBMIT_SUCCESS_MODE = {
  NEW: 'new',
  REVISION: 'revision'
}

// Countdown each send sms - seconds - 30minutes
export const COUNTDOWN_SEND_SMS = 60 * 30 - 1

// VALIDATE EMAIL ADDRESS FORMAT
export const EMAIL_REGEX = /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,4})+$/

// ENVIRONMENT DEV5 AND STG
export const ENV_DEV5 = 'apply.dev5.delivereetech.com'
export const ENV_TEST = 'apply.test.delivereetech.com'
export const ENV_STG = 'apply.stg.delivereetech.com'
export const ENV_PH_DEV5 = 'apply.dev5.transportifytech.com.ph'
export const ENV_PH_STG = 'apply.stg.transportifytech.com.ph'

// LIST PHONE TO RUN AUTO TEST
export const PHONE_NUMBERS_LIST_AUTO_TEST = ['+6698', '+8437', '+6282', '+6390', '+6391']
export const NAMES_LIST_AUTO_TEST = ['Driver DOB Auto']

export const X_USE_CASE = {
  SHOW_MORE: 'show_more',
  NEW_BOOKING: 'new_booking',
}

// Token
export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'

// Area
// MOCK_UP_COUNTRY:  default is id or not set, must have one of this value [id, th, ph, vn, '']
export const MOCK_UP_COUNTRY = process.env.REACT_APP_MOCK_UP_COUNTRY 