import React from 'react'
import { Helmet } from 'react-helmet'
import * as faceapi from 'face-api.js'
import moment from 'moment'
import 'moment/locale/id'
import 'moment/locale/th'
import {
  TRANSPORTIFY_URL,
  LOCALE,
  ACCEPT_LANGUAGE,
  COUNTRY_PHONE_CODE,
  ENV_DEV5,
  ENV_TEST,
  ENV_STG,
  PHONE_NUMBERS_LIST_AUTO_TEST,
  NAMES_LIST_AUTO_TEST,
  ENV_PH_DEV5,
  ENV_PH_STG,
  MOCK_UP_COUNTRY,
} from '../constants/appConstants'
import { ICON_ERROR } from '../constants/imageConstants'
import {
  LIMIT_YEAR_NUMBER,
  DEFAULT_MB_SIZE_FILE,
  DEFAULT_WIDTH_DPI,
  DEFAULT_HEIGHT_DPI,
  LOADING_CAPAICTY,
  APPROVED,
  BLACKLISTED,
  INACTIVE,
  COUNTRY_CODE_THAILAND,
  COUNTRY_CODE_INDONESIA,
  COUNTRY_CODE_PHILIPPINES,
  COUNTRY_CODE_VIETNAM
} from 'constants/dynamicFieldConstants'
import { transform, isEqual, isObject } from 'lodash'
import { BYTES_PER_KB } from '../constants/dynamicFieldConstants'
import * as Sentry from '@sentry/browser'

const _ = window._$g._
const _lodash = require('lodash')

export const detectCountryByUrl = () => {
  const isTransportify = _.includes(TRANSPORTIFY_URL, window.location.hostname)
  let ignoreUrl = ''
  if (_.includes(['select_country'], window.location.pathname.split('/')[1])) {
    ignoreUrl = window.location.pathname.split('/')[1]
  }
  return {
    countryCode: isTransportify ? 'ph' : ignoreUrl ? '' : (window.location.pathname.split('/')[1] || 'id'),
    languageCode: isTransportify || ignoreUrl ? 'en' : ACCEPT_LANGUAGE[`${(window.location.pathname.split('/')[1] || 'id')}`],
    isTransportify,
  }
}

export const verifyUrlCountryCode = (location, callback = (() => { })) => {
  if (typeof (Storage) !== 'undefined') {
    const isTransportify = _.includes(TRANSPORTIFY_URL, location.hostname)
    const isLocale = isTransportify || _.includes(LOCALE, location.pathname.split('/')[1])

    localStorage.removeItem('driver_signup_source')
    if (location.search.includes('utm_source')) {
      const param_source = location.href
      localStorage.setItem('driver_signup_source', param_source)
    }

    let ignoreUrl = ''
    if (_.includes(['select_country', 'sign_up'], location.pathname.split('/')[1])) {
      ignoreUrl = location.pathname.split('/')[1]
    }
    const countryCode = {
      country_code: isTransportify ? 'ph' : ignoreUrl ? '' : (location.pathname.split('/')[1] || 'id'),
      language_code: isTransportify || ignoreUrl ? 'en' : ACCEPT_LANGUAGE[`${(location.pathname.split('/')[1] || 'id')}`],
      isTransportify,
    }
    Object.assign(window._$g, {
      country_code: countryCode.country_code,
      language_code: countryCode.language_code,
      isTransportify: countryCode.isTransportify,
    })
    window.localStorage.setItem('dlvr_verifyCountry', JSON.stringify(countryCode))
    callback(isLocale, ignoreUrl)
  } else {
    // No web storage Support. How to support cookies || session
    console.log('No web storage Support. How to support cookies or session')
    callback()
  }
}

export const configHeadHelmet = (titleContent = '', description = '', languageCode = 'en') => {
  return (
    <Helmet htmlAttributes={{ lang: languageCode }}>
      <title>{titleContent}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export const parseValueField = {
  handle: (inputText, specialCharacters, textReplace) => {
    if (_.isUndefined(inputText)) return inputText
    return inputText.replace(specialCharacters, textReplace)
  },

  OnlyNumber: (inputText, textReplace = '') => {
    const specialCharacters = /[^0-9]/gi
    return parseValueField.handle(inputText, specialCharacters, textReplace)
  },
  // \u0E00-\u0E7F thailand
  // character and space
  OnlyCharacter: (inputText, textReplace = '') => {
    const specialCharacters = /[^ A-Za-z\u0E00-\u0E7F]/gi
    return parseValueField.handle(inputText, specialCharacters, textReplace)
  },
  // number, character, space
  NumberAndCharacter: (inputText, attribute_key = '', textReplace = '') => {
    const preventFirstChar = /^[,.]/
    inputText = inputText.replace(preventFirstChar, '')
    const specialCharacters = attribute_key === LOADING_CAPAICTY ? /[^ A-Za-z0-9.\u0E00-\u0E7F]/gi : /[^ A-Za-z0-9\u0E00-\u0E7F]/gi
    return parseValueField.handle(inputText, specialCharacters, textReplace)
  },
  //Accept all character
  AcceptAllCharacter: (inputText) => {
    return inputText
  }
}

export const usrImgBase64 = (img) => {
  function toDataURL(url, callback) {
    const xhr = new XMLHttpRequest()
    xhr.onload = () => {
      const reader = new FileReader()
      reader.onloadend = () => {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }
  return new Promise((resolve) => {
    toDataURL(img, (dataUrl) => {
      resolve(dataUrl)
    })
  })
}

export const getQualityImage = (size) => {
  let qualityImage
  if (_.inRange(size, 200, DEFAULT_MB_SIZE_FILE)) {
    qualityImage = 0.5
  } else if (_.inRange(size, (DEFAULT_MB_SIZE_FILE + 1), (DEFAULT_MB_SIZE_FILE * 2))) {
    qualityImage = 0.4
  } else if (_.inRange(size, (DEFAULT_MB_SIZE_FILE * 2 + 1), (DEFAULT_MB_SIZE_FILE * 3))) {
    qualityImage = 0.3
  } else if (_.inRange(size, (DEFAULT_MB_SIZE_FILE * 3 + 1), (DEFAULT_MB_SIZE_FILE * 4))) {
    qualityImage = 0.2
  } else {
    qualityImage = 0.15
  }
  return qualityImage
}

export const getResolutionDPI = (originWidth, originHeight) => {
  let width, height, scaleDPI
  if (originWidth > originHeight && originWidth > DEFAULT_WIDTH_DPI) {
    scaleDPI = originWidth / DEFAULT_WIDTH_DPI
    width = parseInt(originWidth / scaleDPI, 10)
    height = parseInt(originHeight / scaleDPI, 10)
  } else if (originHeight > originWidth && originHeight > DEFAULT_HEIGHT_DPI) {
    scaleDPI = originHeight / DEFAULT_HEIGHT_DPI
    width = parseInt(originWidth / scaleDPI, 10)
    height = parseInt(originHeight / scaleDPI, 10)
  } else {
    width = originWidth
    height = originHeight
  }

  return {
    width,
    height
  }
}

export const compressImage = (img, originWidth, originHeight, qualityImage = 0.8) => {
  if (window.File && window.FileReader && window.FileList && window.Blob) {
    const canvas = document.createElement('canvas')
    const { width, height } = getResolutionDPI(originWidth, originHeight)
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, originWidth, originHeight, 0, 0, canvas.width, canvas.height)
    return canvas.toDataURL('image/jpeg', qualityImage)
  }
  return null
}

export const getDateObj = (datetime) => {
  if (_lodash.isNull(datetime) || datetime === '') {
    return null
  }
  const date = new Date(datetime)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const date_obj = {
    day: day,
    month: month,
    year: year
  }
  return date_obj
}

export const getLimitLicenseExpirationDate = () => {
  var d = new Date()
  var year = d.getFullYear()
  var month = d.getMonth()
  var day = d.getDate()
  return new Date(year + LIMIT_YEAR_NUMBER, month, day)
}

export const isMobile = {
  Android: function android() {
    return navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function lackBerry() {
    return navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: function iOs() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  Opera: function opera() {
    return navigator.userAgent.match(/Opera Mini/i)
  },
  Windows: function windows() {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
  },
  any: function any() {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
  }
}

export const isWebView = () => {
  const getAndroidVersion = () => {
    const userAgent = navigator.userAgent.toLowerCase()
    var match = userAgent.match(/android\s([0-9\.]*)/i) //eslint-disable-line
    return match ? match[1] : undefined
  }
  if (getAndroidVersion()) {
    if (parseInt(getAndroidVersion(), 10) >= 5) {
      return navigator.userAgent.match(/wv/i) && navigator.userAgent.match(/Mobile/i)
    }
    // If Android version < 5 is Webview.
    // https://developer.chrome.com/multidevice/user-agent
    return true
  }
  return false
}

export const isBrowserMobile = () => isMobile.any() && !isWebView()

export const differenceObject = (object, base) => {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isObject(value) && isObject(base[key]) ? differenceObject(value, base[key]) : value
    }
  })
}

export const handleFocusField = (eventTarget) => {
  const topPos = eventTarget.closest('.c-block.form-group') ? (eventTarget.closest('.c-block.form-group').getBoundingClientRect().top || 0) : 0

  const pageScreenPos = document.getElementsByClassName('mobile-screen')[0]
    ? (document.getElementsByClassName('mobile-screen')[0].getBoundingClientRect().top || 0)
    : 0

  if (document.getElementById('c-mainview-id')) {
    setTimeout(() => {
      document.getElementById('c-mainview-id').scrollTop = document.getElementById('c-mainview-id').scrollTop + parseInt(topPos - 50, 10) - pageScreenPos
    }, 0)
  }
}

export const renderTextError = (textError) => (
  <div className='c-block-invalid'>
    <span className="d-flex mr5">
      <img src={ICON_ERROR} alt='' />
    </span>
    {textError}
  </div>
)

// full country codes: https://gist.githubusercontent.com/Goles/3196253/raw/9ca4e7e62ea5ad935bb3580dc0a07d9df033b451/CountryCodes.json
// project using 4 country
// input: +84123456789, +840123456789, 0123456789, 123456789
// output +84123456789
export const convertPhoneToDialCodePhone = (oldPhone = '') => {
  const countryCode = window._$g.country_code.toUpperCase()
  const dialCode = COUNTRY_PHONE_CODE[countryCode.toLowerCase()]
  if (countryCode && dialCode) {
    let newPhone = (oldPhone[0] === ' ' ? `+${oldPhone.substring(1)}` : oldPhone).replace(dialCode, '')
    newPhone = newPhone.charAt(0) === '0' ? newPhone.slice(1) : newPhone
    return dialCode + newPhone
  }
  return oldPhone
}

const handleBeforeUnload = (event) => {
  event.returnValue = "Data will be lost if you leave the page, are you sure?"
}

export const onBeforeUnload = () => {
  window.addEventListener("beforeunload", handleBeforeUnload)
}

export const removeBeforeUnload = () => {
  window.removeEventListener('beforeunload', handleBeforeUnload)
}

// COPIED FROM PDFView
export const getSizeImage64 = (imageUrl) => {
  const stringLength = imageUrl.length - 'data:image/png;base64,'.length
  const sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812

  return parseInt(sizeInBytes / 1000, 10)
}

export const transformDocumentKeyToSignatureKey = (documentKey) => {
  if (!documentKey) return null

  // Do not transform key that
  //  - includes document_signature
  //  - not includes document
  if (documentKey.includes('document_signature_') || !documentKey.includes('document_')) {
    return documentKey
  }

  // Rules: documentKey should have this format: [document]_[index]
  // --> signature key will be: [document]_signature_[index]
  const parts = documentKey.split('_')

  // In case of invalid format detected, do nothing
  if (parts.length !== 2) return null

  return `${parts[0]}_signature_${parts[1]}`
}

export const detectPerson = async (img, width, height, phone = '', name = '') => {
  try {
   const detections = await faceapi.detectAllFaces(img, new faceapi.TinyFaceDetectorOptions({ inputSize: 224 }))
   if (detections && detections.length === 1) {
     // extract square
     const expand = Math.round(width / 5)
     const x = detections[0].box.x - expand
     const y = detections[0].box.y - expand
     const widthRect = detections[0].box.width + expand * 2
     const heightRect = detections[0].box.height + expand * 2

     const regionsToExtract = [
       new faceapi.Rect(
         x > 0 ? x : 0,
         y > 0 ? y : 0,
         widthRect < width ? widthRect : width,
         heightRect < height ? heightRect : height
       )
     ]
     const canvases = await faceapi.extractFaces(img, regionsToExtract)
     const base64Img = canvases.length === 1 ? canvases[0].toDataURL() : false
     return base64Img
   } else {
    const hostName = window.location.hostname
    const driverTestPhone = convertPhoneToDialCodePhone(phone.value).slice(0,5)
    const validPhone = PHONE_NUMBERS_LIST_AUTO_TEST.includes(driverTestPhone)
    const validName = NAMES_LIST_AUTO_TEST.includes(name)
    // Disable face-check on dev5 and stg to run auto-test
    if ((hostName === ENV_TEST || hostName === ENV_DEV5 || hostName === ENV_STG || hostName === ENV_PH_STG || hostName === ENV_PH_DEV5) && validPhone && validName) {
      return img.currentSrc
    }
    return false
  }
  } catch (error) {
   Sentry.captureException(new Error(error))
   return false
 }
}

export const getPersonAfterCompress = async (base64Img) => {
  const results = {
    status: false,
    msg: '',
    value: ''
  }
  if (!base64Img) return results
  // const head = 'data:image/jpeg;base64,'
  // const imgPersonFileSize = Math.round((base64Img.length - head.length) * 3 / 4)
  // const fileSizeInKb = Math.trunc(imgPersonFileSize / BYTES_PER_KB)
  const img = new Image()
  img.src = base64Img
  return new Promise((resolve, reject) => {
    img.onload = () => {
      // Note: compress image in case it's greater than 200kb
      // const base64AfterCompressing = fileSizeInKb > 200
      //   ? compressImage(img, img.width, img.height, getQualityImage(imgPersonFileSize))
      //   : base64Img
      // Restrict file size: 3Mb (3 * 1024 kb) after compressing
      const fileSizeInKbAfterCompressing = getSizeImage64(base64Img)
      if (fileSizeInKbAfterCompressing > (3 * BYTES_PER_KB)) {
        // Trigger error and stop 
        results.msg = 'File is too big'
      } else {
        results.status = true
        results.value = base64Img
      }
      resolve(results)
    }
  })
}

export const validURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return !!pattern.test(str)
}

export const loadJS = ({ id, src, callback }) => {
  // Use callback if the script element is existed
  if (document.getElementById(id)) {
    callback()
    return
  }

  // Create a async script element
  const script = document.createElement('script')
  script.id = id
  script.src = src
  script.async = true

  // Append to body
  const body = document.querySelector('body') || null
  if (body) {
    body.appendChild(script)
  }
}

export const setLocale = () => {
  const verifyCountry = JSON.parse(window.localStorage.getItem('dlvr_verifyCountry')) || {}
  const languageCode = verifyCountry.language_code || 'en'
  const localeCodeSupported = ['th', 'id']
  const localeCode = localeCodeSupported.includes(languageCode) ? languageCode : 'en'
  moment.locale(localeCode)
}

// back to signup of driver app from native app
export const goToSignUpDriverApp = () => {
  try {
    // eslint-disable-next-line no-undef
    Android.exitApp("sign_up_of_driver_app")
  } catch (error) {
    console.log('Oops failed to signup of driver app')
  }
}
export const findValueInSession = (dataid, sessionName) => {  
  const dataSession = JSON.parse(sessionStorage.getItem(sessionName))
  if(dataSession){
    const findData = dataSession.find(element => element.id === dataid)
    return findData ? findData.name : null
  }
  return null
}

// trigger event to track by AppsFlyer
export const funcTrackingEventToAppsflyer = (eventName, objparam) => {
  try {
    // eslint-disable-next-line no-undef
    Android.funcTrackingEventToAppsflyer(eventName, objparam)
  } catch (error) {
    console.log('Oops failed to signup of driver app')
  }
}

export const fncReportIssue = (driver_report_issue_url) => {
  const countryCode = detectCountryByUrl().countryCode
  if(!isWebView() && countryCode === COUNTRY_CODE_INDONESIA && driver_report_issue_url) {
    window.open(
      driver_report_issue_url,
      '_blank'
    )
  } else {
    try {
      // eslint-disable-next-line no-undef
      Android.fncReportIssue()
    } catch (error) {
      console.log('Oops failed to signup of driver app')
    }
  }
}

export const fnSuspendCompetitorApp = (approve_status) => {
  try {
    if (approve_status !== APPROVED && approve_status !== BLACKLISTED && approve_status !== INACTIVE) {
      // eslint-disable-next-line no-undef
      Android.fnSuspendCompetitorApp()
    }
  } catch (error) {
    console.log('Oops failed to signup of driver app')
  }
}

export const moengageTrackEvent = async (eventName, params = {}) => {
  try {
    await window.Moengage.track_event(eventName, params)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export const setDriverRegistrationID = (id) => {
  try {
    // eslint-disable-next-line no-undef
    Android.setDriverRegistrationID(`${id}_DO`)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export const handleRedirectNotificationCenterScreen = (unreadNumber = 0) => {
  try {
    // eslint-disable-next-line no-undef
    Android.showNotificationCenterScreen(unreadNumber)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export const isWebViewAndroid = () => isWebView() && (isMobile.Android() || isMobile.BlackBerry())

const isInCountry = (country) => {
  const verifyCountry = JSON.parse(window.localStorage.getItem('dlvr_verifyCountry'))
  const isInVN = _.includes([COUNTRY_CODE_VIETNAM], verifyCountry.country_code)
  return isInVN ? (MOCK_UP_COUNTRY === country || !MOCK_UP_COUNTRY)
    : _.includes([country], verifyCountry.country_code)
}

export const getCountryByVerifyCountry = () => {
  const verifyCountry = JSON.parse(window.localStorage.getItem('dlvr_verifyCountry'))
  const isInVN = _.includes([COUNTRY_CODE_VIETNAM], verifyCountry.country_code)
  const isInID = isInCountry(COUNTRY_CODE_INDONESIA)
  const isInPH = isInCountry(COUNTRY_CODE_PHILIPPINES)
  const isInTH = isInCountry(COUNTRY_CODE_THAILAND)
  return { isInPH, isInVN, isInID, isInTH }
}

export const addQueryParamToUrl = (pathName, paramName, paramValue) => {
  // Path already contains query parameters
  if (pathName.includes("?")) return `${pathName}&${paramName}=${paramValue}`
  // No existing query parameters
  return `${pathName}?${paramName}=${paramValue}`
}

export const getParamFromURL = (param) => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}